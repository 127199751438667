import { compact, isEmpty, startCase } from "lodash";
import React, { useEffect, useState, useContext } from "react";
import { Form, Table, Button, Modal, Container } from "react-bootstrap";
import constants from "../../utils/constants";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useForm } from "react-hook-form";
import { apiPut, apiPost, apiGet } from "../../utils/apiFetch";
import apiPath from "../../utils/apiPath";
import helpers from "../../utils/helpers";
import { toast } from "wc-toast";
import UpdateStatusUser from "../../components/UpdateStatusUser";
import UpdateDialogBox from "../../components/UpdateDialogBox";
import AuthContext from "../../context/AuthContext";

const UserList = (props) => {
  const [currentItems, setCurrentItems] = useState([]);
  const location = useLocation();
  const user_params = compact(location.pathname.split("/"));
  const [pageCount, setPageCount] = useState(0);
  const [changeReference, setChangeReference] = useState(false);
  const setChangeReferenceToggle = () => setChangeReference(!changeReference);
  const { user } = useContext(AuthContext);
  const [changeAwcLimit, setCreditAwcLimit] = useState(false);
  const setChangeAwcLimitToggle = () => setCreditAwcLimit(!changeAwcLimit);
  let userCont = user;
  const [getUpdateId, setUpdateId] = useState("");
  const [exposureData, setExposureData] = useState("");
  const [exposure, setExposure] = useState(false);
  const [changeStatus, setChangeStatus] = useState(false);
  const [userData, setUserData] = useState({});
  const [getActiveClass, setActiveClass] = useState("");
  const [currentStatus, setCurrentStatus] = useState("downline");

  const setChangeStatusToggle = (item) => {
    if (item) {
      setChangeStatus(!changeStatus);
      setUserData(item);
      setActiveClass(item.status);
      // setCurrentStatus(item.status);
    } else {
      setChangeStatus(!changeStatus);
    }
  };

  const setExposureToggle = async (type, id) => {
    try {
      const { status, data: response_users } = await apiGet(
        apiPath.getExpouserData + `?userType=${type}&userId=${id}`
      );
      if (status === 200) {
        setExposureData(response_users.results.data);
        setExposure(true);
      }
    } catch (err) {}
  };

  const [isLoader, setLoader] = useState(false);

  useEffect(() => {
    setCurrentItems(props?.results?.data || []);
    setPageCount(Math.ceil(props?.results?.totalPages) || 0);
    // setItemOffset(props?.results?.limit || [])
  }, [props]);
  const [getCreditReference, setCreditReference] = useState("");

  const setDataForEditReference = (id, creditReference) => {
    setUpdateId(id);
    setCreditReference(creditReference);
    setChangeReferenceToggle();
  };

  const setDataForEditAwcLimit = (id, awcLimit) => {
    setUpdateId(id);
    setCreditAwcLimit(awcLimit);
    setChangeAwcLimitToggle();
  };

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
    reset: reset1,
  } = useForm({});
  const onSubmit1 = async (request) => {
    setLoader(true);
    try {
      const { status, data: response_users } = await apiPost(
        apiPath.updateUserReferenceAmount,
        {
          user_id: getUpdateId,
          reference_amount: request.reference_amount,
          password: request.mypassword,
        }
      );
      if (status === 200) {
        if (response_users.success) {
          setChangeReferenceToggle();
          setLoader(false);
          props.getUsers();
          toast.success(response_users.message);
          reset1();
        } else {
          setLoader(false);
          toast.error(response_users.message);
        }
      }
    } catch (err) {
      setLoader(false);
      toast.error(err.response.data.message);
    }
  };

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    reset: reset2,
  } = useForm({});
  const onSubmit2 = async (request) => {
    setLoader(true);
    try {
      const { status, data: response_users } = await apiPost(
        apiPath.updateAwcLimit,
        {
          user_id: getUpdateId,
          limit: request.awcLimit,
          password: request.mypassword,
        }
      );
      if (status === 200) {
        if (response_users.success) {
          setChangeAwcLimitToggle();
          setLoader(false);
          props.getUsers();
          toast.success(response_users.message);
          reset2();
        } else {
          setLoader(false);
          toast.error(response_users.message);
        }
      }
    } catch (err) {
      setLoader(false);
      toast.error(err.response.data.message);
    }
  };

  const [blockMatchStatus, setMatchBlockStatus] = useState(false);
  const setMatchBlockStatusToggle = () =>
    setMatchBlockStatus(!blockMatchStatus);
  const [getMatchStatus, setMatchStatus] = useState("");
  const [BlockMarket, setBlockMarket] = useState(false);
  const setChangeBlockMarketToggle = () => setBlockMarket(!BlockMarket);
  const [getMarketBlockUserId, setMarketBlockUserId] = useState("");

  const setChangeBlockMarket = async (item) => {
    if (item) {
      setMarketBlockUserId(item?._id);
      const { status, data: response_users } = await apiGet(
        apiPath.sportList + "?userId=" + item?._id
      );
      if (status === 200) {
        if (response_users.success) {
          setSportData(response_users.results);
          setChangeBlockMarketToggle();
        }
      }
    }
  };

  const [sportsListingUpdate, setSportsListingUpdate] = useState({});
  const [sportsListingUpdateId, setSportsListingUpdateId] = useState("");
  const [getSportData, setSportData] = useState("");

  const mySportData = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.sportList + "?userId=" + getMarketBlockUserId
    );
    if (status === 200) {
      if (response_users.success) {
        setSportData(response_users.results);
      }
    }
  };

  const updateMatchStatusSports = async () => {
    // setLoader(true);
    console.log(
      "sportsListingUpdateId",
      sportsListingUpdateId,
      sportsListingUpdate,
      getMarketBlockUserId
    );

    try {
      const { status, data: response_users } = await apiPut(
        apiPath.updateSportsListingStatus + `/${sportsListingUpdateId}`,
        sportsListingUpdate
      );
      if (status === 200) {
        if (response_users.success) {
          mySportData();
          toast.success(response_users.message);
          reset();
        } else {
          toast.error(response_users.message);
          setLoader(false);
        }
      }
    } catch (err) {
      setLoader(false);
      toast.error(err.response.data.message);
    }
  };
  const reset = () => {
    setMatchBlockStatusToggle();
    setLoader(false);
    setSportsListingUpdateId("");
    setSportsListingUpdate({});
  };

  return (
    <>
      <section className="account-table downline-table">
        <div className="container-fluid px-0">
          <div className="responsive table-color">
            <Table>
              <thead>
                <tr>
                  {/* <th scope="col">Sr .No.</th> */}
                  <th scope="col">Account</th>
                  <th scope="col">Website Name</th>
                  <th scope="col" className="text-end">
                    Credit Ref.
                  </th>
                  <th scope="col" className="text-end">
                    Balance
                  </th>
                  {(currentItems && currentItems[0]?.userType == "super_admin" && props.allow_domain_access=="yes") || 
                  (props?.data=="owner" && currentItems && currentItems[0]?.userType == "sub_owner") || 
                  (props?.data=="owner" && currentItems && currentItems[0]?.userType == "super_admin") ? (
                    <th scope="col" className="text-end">
                      Awc Limit
                    </th>
                  ) : (
                    ""
                  )}
                  {currentItems && currentItems[0]?.userType == "user" ? (
                    <th scope="col" className="text-end">
                      Exposure
                    </th>
                  ) : (
                    <th scope="col" className="text-end">
                      Player Exposure
                    </th>
                  )}

                  <th scope="col" className="text-end">
                    Avail. bal.{" "}
                  </th>
                  {currentItems && currentItems[0]?.userType != "user" ? (
                    <th scope="col" className="text-end">
                      Player Balance{" "}
                    </th>
                  ) : (
                    ""
                  )}
                  {user_params[1] == "agent" || props.data == "agent" ? (
                    <th scope="col" className="text-end">
                      Exposure Limit
                    </th>
                  ) : (
                    ""
                  )}
                  <th scope="col" className="text-end">
                    Reference P/L
                  </th>
                  <th scope="col" className="text-end">
                    Status
                  </th>
                  <th scope="col" className="text-end">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItems?.map((user, index) => {
                  const balance =
                    user?.userType == "user"
                      ? Math.sign(
                          user?.totalCoins1 + Math.abs(user?.exposure)
                        ) === -1
                        ? (
                            user?.totalCoins1 + Math.abs(user?.exposure)
                          ).toFixed(2)
                        : (
                            user?.totalCoins1 + Math.abs(user?.exposure)
                          ).toFixed(2)
                      : Math.sign(user?.totalCoins) === -1
                      ? user?.totalCoins.toFixed(2)
                      : user?.totalCoins.toFixed(2);
                  return (
                    <tr key={index}>
                      {/* <td>{index+1}</td> */}
                      <td className="text-start">
                        {user?.userType == "user" ? (
                          <>
                            <a href={"#"} className="text-primary">
                              <span>
                                {/* {constants?.user_status[user?.userType || ""]} */}
                                PL
                              </span>
                            </a>
                            {user?.username || null}
                          </>
                        ) : (
                          <Link
                            to={`/${user._id}/${user?.userType}`}
                            className="text-primary"
                          >
                            <span>
                              {constants?.user_status[user?.userType || ""]}
                            </span>
                            {user?.username || null}
                          </Link>
                        )}
                      </td>
                      <td>{user?.website}</td>

                      <td className="text-end">
                        <a
                          href="#"
                          onClick={function (e) {
                            setDataForEditReference(
                              user._id,
                              user?.creditReference
                            );
                          }}
                          className="text-primary"
                        >
                          {`${helpers.currencyFormat(user?.creditReference)}` ||
                            null}{" "}
                          <i className="fas fa-pen ps-1"></i>
                        </a>
                      </td>
                      <td className="text-primary text-end">
                        {/* user?.userType == "agent"
                          ? Math.sign(user?.totalCoins +Math.abs(user?.exposure)) === -1 ? <p style={{color:`red`}}> {helpers.currencyFormat((user?.totalCoins +Math.abs(user?.exposure)).toFixed(2))}</p>:helpers.currencyFormat((user?.totalCoins +Math.abs(user?.exposure)).toFixed(2))
                         :  */}
                        {user?.userType == "user" ? (
                          Math.sign(
                            user?.totalCoins1 + Math.abs(user?.exposure)
                          ) === -1 ? (
                            <p style={{ color: `red` }}>
                              {" "}
                              {helpers.currencyFormat(
                                (
                                  user?.totalCoins1 + Math.abs(user?.exposure)
                                ).toFixed(2)
                              )}
                            </p>
                          ) : (
                            helpers.currencyFormat(
                              (
                                user?.totalCoins1 + Math.abs(user?.exposure)
                              ).toFixed(2)
                            )
                          )
                        ) : Math.sign(user?.totalCoins) === -1 ? (
                          <p style={{ color: `red` }}>
                            {" "}
                            {helpers.currencyFormat(
                              user?.totalCoins.toFixed(2)
                            )}
                          </p>
                        ) : (
                          `${helpers.currencyFormat(
                            user?.totalCoins.toFixed(2)
                          )}`
                        )}
                      </td>
                      {(currentItems && currentItems[0]?.userType == "super_admin" && props.allow_domain_access=="yes") || 
                  (props?.data=="owner" && currentItems && currentItems[0]?.userType == "sub_owner") || 
                  (props?.data=="owner" && currentItems && currentItems[0]?.userType == "super_admin")? (
                        <td className="text-end">
                          <a
                            href="#"
                            onClick={function (e) {
                              setDataForEditAwcLimit(user._id, user?.awcLimit);
                            }}
                            className="text-primary"
                          >
                            {user?.usedAwcLimit >= 0 || !user?.usedAwcLimit
                              ? `${helpers.currencyFormat(
                                  (
                                    Math.abs(user?.awcLimit) -
                                    Math.abs(user?.usedAwcLimit)
                                  ).toFixed(2)
                                )}`
                              : `${helpers.currencyFormat(user?.awcLimit)}`}
                            <i className="fas fa-pen ps-1"></i>
                          </a>
                        </td>
                      ) : (
                        ""
                      )}
                      <td className="text-end">
                        <Link
                          to="#"
                          onClick={() =>
                            setExposureToggle(user?.userType, user?._id)
                          }
                        >
                          <span className="status-suspend1">
                            {helpers.currencyFormat(user?.exposure) || "0.00"}
                          </span>
                        </Link>
                      </td>
                      <td className="text-end">
                        {user?.userType == "user" ? (
                          Math.sign(
                            helpers.currencyFormat(user?.totalCoins1)
                          ) === -1 ? (
                            <p style={{ color: `red` }}>
                              {" "}
                              {helpers.currencyFormat(
                                user?.totalCoins1.toFixed(2)
                              )}
                            </p>
                          ) : (
                            helpers.currencyFormat(user?.totalCoins1.toFixed(2))
                          )
                        ) : Math.sign(
                            helpers.currencyFormat(
                              user?.totalCoins - Math.abs(user?.exposure)
                            )
                          ) === -1 ? (
                          <p style={{ color: `red` }}>
                            {" "}
                            {helpers.currencyFormat(
                              user?.totalCoins - Math.abs(user?.exposure)
                            )}
                          </p>
                        ) : (
                          helpers.currencyFormat(
                            user?.totalCoins - Math.abs(user?.exposure)
                          )
                        )}
                      </td>
                      {user?.userType != "user" && (
                        <td className="text-end">
                          {" "}
                          {helpers.currencyFormat(user?.playerBalance) ||
                            "0.00"}{" "}
                        </td>
                      )}

                      {user_params[1] == "agent" || props.data == "agent" ? (
                        <td className="text-end">
                          {helpers.currencyFormat(user?.exposureLimit)}
                        </td>
                      ) : (
                        ""
                      )}
                      <td className="text-end">
                        {user.creditReference > 0 ? (
                          <>
                          {user_params[1] == "agent" ?
                            <span
                              style={{
                                color:
                                  user.creditReference - balance >= 0
                                    ? "red"
                                    : "green",
                              }}
                            >
                              {helpers.currencyFormat(
                                user.creditReference - balance
                              )}
                            </span>
                            :
                            <span
                              style={{
                                color:
                                  user.creditReference - balance >= 0
                                    ? "green"
                                    : "red",
                              }}
                            >
                              {helpers.currencyFormat(
                                user.creditReference - balance
                              )}
                            </span>
                            }
                          </>
                        ) : (
                          0
                        )}
                      </td>

                      <td className="text-end">
                        <strong
                          className={classNames({
                            "status-active": user?.status === "active",
                            "status-suspend px-3": user?.status === "suspend",
                            "status-locked px-3": user?.status === "locked",
                          })}
                        >
                          {`${startCase(user?.status)}` || ""}
                        </strong>
                      </td>
                      <td className="action_link text-end">
                        {(props?.data == "super_admin" ||
                          props?.data == "sub_owner" ||
                          props?.data == "owner") && (
                          <>
                            <Link
                              title="Betting Profit Loss"
                              to={`/betting-profit-loss/${user?._id}/${user?.userType}`}
                              className="btn"
                            >
                              <svg
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 32 32"
                                height="1.25rem"
                                width="1.25rem"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M27.935 13.581a12.24 12.24 0 0 1-.653 7.463l.004.004-1.908-1.1.251 7.209L32 23.769l-2.054-1.183c3.201-7.08.57-15.57-6.3-19.535A15.29 15.29 0 0 0 8.41 3.015l1.517 2.657a12.24 12.24 0 0 1 18.008 7.909ZM4.717 11.553a12.239 12.239 0 0 0 17.356 15.37l1.517 2.657a15.29 15.29 0 0 1-15.237-.036C1.484 25.58-1.147 17.09 2.054 10.01L0 8.83l6.37-3.388.252 7.21-1.905-1.1Zm12.613-3.12a1.62 1.62 0 0 0-1.109-.432L16.216 8c-.415 0-.814.155-1.108.432-.294.277-.46.653-.46 1.044v.223c-.81.18-1.56.497-2.19.934-1.02.707-1.808 1.805-1.808 3.151 0 1.146.44 2.254 1.601 2.99 1.029.652 2.412.867 3.967.867 1.367 0 2.08.2 2.416.406.197.121.38.298.38.897 0 .437-.165.669-.517.868-.449.253-1.216.434-2.279.434-1.115 0-1.98-.345-2.437-.729a1.63 1.63 0 0 0-1.132-.374 1.608 1.608 0 0 0-1.082.49 1.408 1.408 0 0 0-.245 1.624c.09.173.214.33.368.46.785.662 1.823 1.117 2.96 1.334v.2c0 .392.165.767.46 1.044a1.62 1.62 0 0 0 1.108.432 1.62 1.62 0 0 0 1.109-.432c.294-.277.46-.652.46-1.044v-.162c.827-.121 1.63-.354 2.325-.747 1.226-.694 2.038-1.85 2.038-3.398 0-1.384-.544-2.597-1.802-3.37-1.116-.684-2.585-.883-4.128-.883-1.353 0-1.97-.209-2.214-.364-.112-.07-.217-.152-.217-.541 0-.188.107-.48.535-.777.428-.296 1.093-.525 1.897-.525 1.118 0 1.915.432 2.242.838.123.159.279.293.457.394a1.664 1.664 0 0 0 1.201.165 1.61 1.61 0 0 0 .558-.254c.165-.119.305-.268.409-.438a1.392 1.392 0 0 0 .153-1.134 1.448 1.448 0 0 0-.281-.519c-.745-.923-1.89-1.552-3.17-1.834v-.225c0-.391-.166-.766-.46-1.043Z"
                                ></path>
                              </svg>
                            </Link>

                            <Link
                              title="Betting Profit Loss"
                              to={`/betting-profit-loss/${user?._id}/${user?.userType}`}
                              className="btn"
                            >
                              <svg
                                viewBox="0 0 19 12"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                height="1.25rem"
                                width="1.25rem"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="m11.372 11.364.257-2.289H8.432c1.1-1.794 1.816-3.077 2.148-3.85.332-.775.498-1.517.498-2.23 0-.856-.236-1.57-.706-2.14C9.902.285 9.286 0 8.525 0c-.766 0-1.45.284-2.052.851l-.257 2.257c.87-.68 1.524-1.02 1.96-1.02.28 0 .516.107.705.321.19.214.284.48.284.795 0 .637-.345 1.584-1.033 2.84-.688 1.255-1.674 2.908-2.959 4.958v.362h6.199Z"
                                ></path>
                                <path
                                  d="m1.96 11.364.488-4.225h1.55c1.016 0 1.826-.313 2.43-.94.606-.626.908-1.53.908-2.714 0-1.033-.282-1.848-.845-2.445C5.93.443 5.196.145 4.295.145H1.286L0 11.364h1.96ZM3.476 5.38h-.825l.39-3.462h.474c.576 0 1.02.149 1.33.446.31.297.465.703.465 1.217 0 .557-.159.996-.475 1.317-.317.321-.77.482-1.36.482ZM12.768 11.364l.488-4.225h1.55c1.016 0 1.827-.313 2.431-.94.605-.626.908-1.53.908-2.714 0-1.033-.282-1.848-.845-2.445-.563-.597-1.295-.895-2.197-.895h-3.008l-1.286 11.219h1.959Zm1.517-5.983h-.824l.389-3.462h.475c.576 0 1.02.149 1.33.446.31.297.464.703.464 1.217 0 .557-.158.996-.475 1.317-.316.321-.77.482-1.359.482Z"
                                  fill-rule="evenodd"
                                ></path>
                              </svg>
                            </Link>
                          </>
                        )}

                        <Link
                          title="Betting Profit Loss"
                          to={`/betting-profit-loss/${user?._id}/${user?.userType}`}
                          className="btn"
                        >
                          <i className="fas fa-exchange-alt swap-icon"></i>
                        </Link>

                        <Link
                          title="Betting History"
                          to={`/betting-history/${user?._id}/${user?.userType}`}
                          className="btn"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            height="1.25rem"
                            width="1.25rem"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                        </Link>

                        {props?.data == "super_admin" ||
                        props?.data == "sub_owner" ||
                        props?.data == "owner" ||
                        helpers.canEditUser(
                          constants?.role[props?.data],
                          constants?.role[user?.userType]
                        ) ? (
                          <>
                            <a
                              title="Change Status"
                              className="btn"
                              onClick={function (e) {
                                setChangeStatusToggle(user);
                              }}
                            >
                              <i className="fas fa-cog"></i>
                            </a>
                          </>
                        ) : userCont?.userType == "agent" ? (
                          <>
                            <a
                              title="Change Status"
                              className="btn"
                              onClick={function (e) {
                                setChangeStatusToggle(user);
                              }}
                            >
                              <i className="fas fa-cog"></i>
                            </a>
                          </>
                        ) : (
                          ""
                        )}

                        <Link
                          title="Account Summary"
                          to={`/account-summary/${user?._id}/${user?.userType}`}
                          className="btn"
                        >
                          <i className="fas fa-user"></i>
                        </Link>

                        {(userCont?.userType == "super_admin" ||
                          userCont?.userType == "sub_owner" ||
                          userCont?.userType == "owner") && (
                          <Link
                            title="Block Market"
                            // to={`/account-summary/${user?._id}/${user?.userType}`}
                            onClick={function (e) {
                              setChangeBlockMarket(user);
                            }}
                            className="btn"
                          >
                            <i className="fas fa-lock"></i>
                          </Link>
                        )}
                      </td>
                    </tr>
                  );
                })}
                {isEmpty(currentItems) ? (
                  <tr>
                    <td colSpan={10}>No records found</td>
                  </tr>
                ) : null}
              </tbody>
            </Table>
            <div className="bottom-pagination">
              <ReactPaginate
                breakLabel="..."
                nextLabel=" >"
                onPageChange={props.handlePageClick}
                pageRangeDisplayed={10}
                pageCount={pageCount}
                previousLabel="< "
                renderOnZeroPageCount={null}
                activeClassName="p-0"
                activeLinkClassName="pagintion-li"
              />
            </div>
          </div>
        </div>
      </section>
      <Modal
        className="exposure_modal"
        show={exposure}
        onHide={() => {
          setExposure(false);
          setExposureData("");
        }}
      >
        <Modal.Body className="p-0">
          <div className="exposure-content">
            <h2>Exposure Information</h2>
            <Table>
              <thead>
                <tr>
                  <th scope="col">Match Name</th>
                  <th scope="col">Market/FancyName</th>
                  {/* <th scope="col">SourceId</th> */}
                  <th scope="col">Type</th>
                  <th scope="col">Exposure</th>
                </tr>
              </thead>
              <tbody>
                {exposureData && exposureData?.length > 0 ? (
                  exposureData?.map((item) => {
                    return (
                      <tr>
                        <td>
                          {item?.betFaireType == "fancy" ||
                          item?.betFaireType == "premium_fancy" ||
                          item?.betFaireType == "bookmaker"
                            ? item?.matchName
                            : item?.gameName}
                        </td>
                        <td>
                          {(item?.eventType == "1" ||
                            item?.eventType == "2" ||
                            item?.eventType == "4") &&
                          item?.betFaireType == "betfair"
                            ? "Match Odds"
                            : (item?.eventType == "1" ||
                                item?.eventType == "2" ||
                                item?.eventType == "4") &&
                              item?.betFaireType == "premium_fancy"
                            ? item?.gameName
                            : item?.runnerName}
                        </td>
                        {/* <td>{item?.selectionId}</td> */}
                        <td>{item?.betType}</td>
                        <td>{Math.abs(item?.amount?.toFixed(2))}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={4}>No Records Found</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 pt-0">
          <Button
            variant="secondary"
            onClick={() => {
              setExposure(false);
              setExposureData("");
            }}
            className="theme_dark_btn"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={changeReference}
        onHide={setChangeReferenceToggle}
        className="change-status-modal"
      >
        <Modal.Header closeButton className="p-0 pb-2">
          <Modal.Title className="modal-title-status h4">
            Credit Reference Edit
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="test-status border-0 text-start">
            <Form
              className="change-password-sec"
              onSubmit={handleSubmit1(onSubmit1)}
            >
              <h4 className="h4 mb-3 curent-value">
                <label>Current :</label>{" "}
                <strong>
                  {helpers.currencyFormat(getCreditReference || null)}
                </strong>
              </h4>

              <Form.Group className="mb-2 d-flex align-items-center">
                <label className="me-2">New</label>
                <Form.Control
                  type="number"
                  placeholder="Enter Reference Amount"
                  className={
                    errors1.reference_amount
                      ? " w-sm-50 is-invalid "
                      : "w-sm-50 "
                  }
                  {...register1("reference_amount", {
                    required: "Please enter reference amount",
                    pattern: {
                      value: /^[0-9\b]+$/,
                      message: "Please enter valid number.",
                    },
                  })}
                />
                {errors1.reference_amount &&
                  errors1.reference_amount.message && (
                    <label className="invalid-feedback text-left">
                      {errors1.reference_amount.message}
                    </label>
                  )}
              </Form.Group>
              <Form.Group className="mb-2 d-flex align-items-center">
                <label className="me-2">Password</label>
                <Form.Control
                  type="password"
                  placeholder="Enter Password"
                  className={
                    errors1.mypassword ? " w-sm-50 is-invalid " : "w-sm-50 "
                  }
                  {...register1("mypassword", {
                    required: "Please enter password",
                  })}
                />
                {errors1.mypassword && errors1.mypassword.message && (
                  <label className="invalid-feedback text-left">
                    {errors1.mypassword.message}
                  </label>
                )}
              </Form.Group>

              <div className="text-center mt-4">
                <Button type="submit" className="theme_dark_btn">
                  {isLoader ? "Loading..." : "Submit"}
                </Button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={changeAwcLimit}
        onHide={setChangeAwcLimitToggle}
        className="change-status-modal"
      >
        <Modal.Header closeButton className="p-0 pb-2">
          <Modal.Title className="modal-title-status h4">
            Casino Limit Edit
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="test-status border-0 text-start">
            <Form
              className="change-password-sec"
              onSubmit={handleSubmit2(onSubmit2)}
            >
              <Form.Group className="mb-2 d-flex align-items-center">
                <label className="me-2">New</label>
                <Form.Control
                  type="number"
                  placeholder="Enter Casino Amount"
                  className={
                    errors2.awcLimit ? " w-sm-50 is-invalid " : "w-sm-50 "
                  }
                  {...register2("awcLimit", {
                    required: "Please enter casino amount",
                    pattern: {
                      value: /^[0-9\b]+$/,
                      message: "Please enter valid number.",
                    },
                  })}
                />
                {errors2.awcLimit && errors2.awcLimit.message && (
                  <label className="invalid-feedback text-left">
                    {errors2.awcLimit.message}
                  </label>
                )}
              </Form.Group>
              <Form.Group className="mb-2 d-flex align-items-center">
                <label className="me-2">Password</label>
                <Form.Control
                  type="password"
                  placeholder="Enter Password"
                  className={
                    errors2.mypassword ? " w-sm-50 is-invalid " : "w-sm-50 "
                  }
                  {...register2("mypassword", {
                    required: "Please enter password",
                  })}
                />
                {errors2.mypassword && errors2.mypassword.message && (
                  <label className="invalid-feedback text-left">
                    {errors2.mypassword.message}
                  </label>
                )}
              </Form.Group>

              <div className="text-center mt-4">
                <Button type="submit" className="theme_dark_btn">
                  {isLoader ? "Loading..." : "Submit"}
                </Button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={BlockMarket}
        onHide={setChangeBlockMarketToggle}
        className="block-market-table"
      >
        <Modal.Header closeButton className="p-0 pb-2">
          <Modal.Title className="modal-title-status h4">
            Block Market
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="test-status border-0 text-start">
            <section className="account-table ">
              <Container fluid className="mt-4 px-0">
                <div className="responsive">
                  <Table>
                    <thead>
                      <tr>
                        <th scope="col">S.No.</th>
                        <th scope="col">Betfair ID </th>
                        <th scope="col">Name </th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                        {/* <th scope="col">Banking</th>
                  <th scope="col">International Market</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {getSportData?.data &&
                        getSportData?.data?.length > 0 &&
                        getSportData?.data?.map((item, key) => (
                          <tr key={key}>
                            <td>{key + 1}</td>
                            <td>{item?.betfairId}</td>
                            <td>
                              <a href="#">{item?.name}</a>
                            </td>
                            <td>
                              {item?.name}{" "}
                              {item?.status === "active" ? " is ON" : " is OFF"}
                            </td>
                            <td>
                              {" "}
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                checked={
                                  item?.status === "active" ? true : false
                                }
                                onClick={function (e) {
                                  setSportsListingUpdate({
                                    status:
                                      item?.status === "active"
                                        ? "inActive"
                                        : "active",
                                    userId: getMarketBlockUserId,
                                  });
                                  setMatchStatus(
                                    item?.status === "active"
                                      ? "InActive"
                                      : "Active"
                                  );
                                  setMatchBlockStatusToggle();
                                  setSportsListingUpdateId(item?._id);
                                }}
                              />
                            </td>
                          </tr>
                        ))}

                      {isEmpty(getSportData.data) ? (
                        <tr>
                          <td colSpan={9}>No records found</td>
                        </tr>
                      ) : null}
                    </tbody>
                  </Table>
                </div>
              </Container>
            </section>
          </div>
        </Modal.Body>
      </Modal>
      <UpdateStatusUser
        changeStatus={changeStatus}
        setChangeStatusToggle={setChangeStatusToggle}
        userData={userData}
        getActiveClass={getActiveClass}
        setActiveClass={setActiveClass}
        getUsers={props.getUsers}
        currentStatus={currentStatus}
      />
      <UpdateDialogBox
        open={blockMatchStatus}
        onClose={setMatchBlockStatusToggle}
        onSubmit={updateMatchStatusSports}
        isLoader={isLoader}
        headerTitle={"Block/Un-Block Match"}
        title={` You Want to ${getMatchStatus} This Match?`}
      />
    </>
  );
};

export default UserList;
